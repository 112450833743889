// React
import { FC, useEffect, useState, useRef } from 'react'

// Libraries
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

// Custom
import {
  ContactUsSection,
  ContactUsContainer,
  CarouselDot,
  CarouselDotContainer,
  ConceptionAnimation,
  ConceptionAnimationContainer,
  ConceptionTextContainer,
  ConceptionsDiv,
  ConceptionsIcon,
  ConceptionsContainer,
  ConceptionsTitle,
  ConceptionSection,
  ConceptionSubTitle,
  ConceptionText,
  ConceptionTitle,
  CustomerCompany,
  CustomerInfoContainer,
  CustomerName,
  CustomerQuote,
  CustomerQuoteContainer,
  CustomerRole,
  CustomerTestimonialContainer,
  CustomerTestimonial,
  DotLine,
  DotLineProject,
  FormContainer,
  Icon,
  IconContainer,
  IntroCard,
  IntroCardText,
  IntroCardTitle,
  IntroCardTitlesContainer,
  IntroSection,
  IntroSectionTop,
  TestimonialSubTitle,
  TestimonialTitle,
  TestimonialTitlesContainer,
  TitlesContainer,
  ImageAnimation,
  IntroCardContainer,
  LogoAnimationContainer,
  ProjectCard,
  ProjectCardIconContainer,
  ProjectCardText,
  ProjectCardTitle,
  ProjectSection,
  ProjectSectionCardsContainer,
  ProjectSectionText,
  ProjectSectionTextContainer,
  ProjectSectionTitle,
  RoleCompanyContainer,
  Text,
  Title,
} from './styled'
import { LightButtonLink, MainButtonLink } from 'components/UI/Buttons'
import { PaddingContainer, SimpleContainer } from 'components/UI/Containers'
import { theme } from 'lib/styled-components/theme'
import { GENERAL, HOME, SEO } from 'locales'
import Form from 'components/Form'
import WrapProvider from 'WrapProvider'
import useMediaQuery from 'hooks/useMediaQuery'

// Assets
import Check from 'images/icons/check.svg'
import ConceptionCard from 'images/images/conceptionCardBackground.png'
import ConceptionDeliver from 'images/images/conceptionDeliver.png'
import ConceptionDev from 'images/images/conceptionDev.png'
import ConceptionHeedsLogo from 'images/images/conceptionHeedsLogo.png'
import ConceptionIdea from 'images/images/conceptionIdea.png'
import ConceptionResearch from 'images/images/conceptionResearch.png'
import Deliver from 'images/icons/deliver.svg'
import Development from 'images/icons/development.svg'
import DotLineBottom01 from 'images/images/dotLineBottom01.png'
import DotLineBottom02 from 'images/images/dotLineBottom02.png'
import DotLineConception01 from 'images/images/dotLineConception01.png'
import DotLineConception02 from 'images/images/dotLineConception02.png'
import DotLineConception03 from 'images/images/dotLineConception03.png'
import DotLineIcon from 'images/icons/dotLine.svg'
import DotLineTop01 from 'images/images/dotLineTop01.png'
import DotLineTop02 from 'images/images/dotLineTop02.png'
import DotLineProjectImg from 'images/images/dotLineProject.png'
import DoubleCheck from 'images/icons/doubleCheck.svg'
import HandHart from 'images/icons/handHart.svg'
import HeedsLogoDarkLeft from 'images/images/heedsLogoDarkLeft.png'
import HeedsLogoDarkRight from 'images/images/heedsLogoDarkRight.png'
import HeedsLogoShadeLeft from 'images/images/heedsLogoShadeLeft.png'
import HeedsLogoShadeRight from 'images/images/heedsLogoShadeRight.png'
import HomeHumanCard from 'images/images/homeHumanCard.png'
import HomeRobotCard from 'images/images/homeRobotCard.png'
import Idea from 'images/icons/idea.svg'
import Lightning from 'images/icons/lightning.svg'
import Lock from 'images/icons/lock.svg'
import Quote from 'images/icons/quoteIcon.svg'
import Research from 'images/icons/research.svg'
import Star from 'images/icons/starModal.svg'
import SeoElement from 'components/SeoElement'

const IMAGE_ANIMATION = [
  {
    image: DotLineBottom01,
    top: '341',
    right: '636',
    animationUp: true,
    animationType: 'pop1',
  },
  {
    image: DotLineBottom02,
    top: '351',
    right: '655',
    animationUp: false,
    animationType: 'pop2',
  },
  {
    image: DotLineTop01,
    top: '86',
    right: '105',
    animationUp: false,
    animationType: 'pop1',
  },
  {
    image: DotLineTop02,
    top: '100',
    right: '128',
    animationUp: true,
    animationType: 'pop2',
  },
  {
    image: HeedsLogoDarkLeft,
    top: '132',
    right: '370',
    animationUp: false,
    animationType: 'leftLogo',
  },
  {
    image: HeedsLogoDarkRight,
    top: '182',
    right: '200',
    animationUp: false,
    animationType: 'rightLogo',
  },
  {
    image: HeedsLogoShadeLeft,
    top: '152',
    right: '370',
    animationUp: true,
    animationType: 'leftLogoShade',
  },
  {
    image: HeedsLogoShadeRight,
    top: '162',
    right: '200',
    animationUp: true,
    animationType: 'rightLogoShade',
  },
  {
    image: HomeHumanCard,
    top: '345',
    right: '50',
    animationUp: false,
    animationType: 'pop2',
  },
  {
    image: HomeRobotCard,
    top: '143',
    right: '583',
    animationUp: true,
    animationType: 'pop1',
  },
  {
    image: Star,
    top: '95',
    right: '582',
    animationUp: false,
  },
  {
    image: Star,
    top: '409',
    right: '477',
    animationUp: true,
  },
]

const INTRO_CARDS = [
  { title: HOME.intro.innovation.title, text: HOME.intro.innovation.subtitle },
  { title: HOME.intro.collab.title, text: HOME.intro.collab.subtitle },
  { title: HOME.intro.collab.title, text: HOME.intro.collab.subtitle },
]

const CONCEPTION = [
  {
    icon: { mobile: Check, desk: Research },
    title: { mobile: HOME.conception.search, desk: HOME.conception.search },
  },
  {
    icon: { mobile: Check, desk: Development },
    title: { mobile: HOME.conception.develop, desk: HOME.conception.develop },
  },
  {
    icon: { mobile: Check, desk: Idea },
    title: { mobile: HOME.conception.idea, desk: HOME.conception.idea },
  },
  {
    icon: { mobile: Check, desk: Deliver },
    title: { mobile: HOME.conception.delivery, desk: HOME.conception.delivery },
  },
]

const CONCEPTION_ANIMATION = [
  { image: ConceptionCard, top: '72', right: '623', animated: false },
  { image: ConceptionHeedsLogo, top: '80', right: '640', animated: true },
  { image: ConceptionCard, top: '234', right: '623', animated: true },
  { image: ConceptionResearch, top: '264', right: '663', animated: false },
  { image: ConceptionCard, top: '72', right: '376', animated: true },
  { image: ConceptionDev, top: '100', right: '430', animated: false },
  { image: ConceptionCard, top: '234', right: '376', animated: false },
  { image: ConceptionIdea, top: '260', right: '426', animated: true },
  { image: ConceptionCard, top: '72', right: '111', animated: false },
  { image: ConceptionDeliver, top: '95', right: '145', animated: true },
  { image: ConceptionCard, top: '254', right: '183', animated: true },
  { image: ConceptionHeedsLogo, top: '265', right: '200', animated: false },
  { image: DotLineConception01, top: '145', right: '476', animated: false },
  { image: DotLineConception01, top: '145', right: '723', animated: false },
  { image: DotLineConception02, top: '300', right: '476', animated: false },
  { image: DotLineConception02, top: '135', right: '211', animated: false },
  { image: DotLineConception03, top: '135', right: '142', animated: false },
]

const PROJECT_CARDS = [
  {
    icon: Lock,
    title: HOME.projects.cards.ideas.title,
    text: HOME.projects.cards.ideas.subtitle,
  },
  {
    icon: HandHart,
    title: HOME.projects.cards.exp.title,
    text: HOME.projects.cards.exp.subtitle,
  },
  {
    icon: DoubleCheck,
    title: HOME.projects.cards.commit.title,
    text: HOME.projects.cards.commit.subtitle,
  },
]

const CUSTOMER_TESTIMONIAL = [
  {
    quote: '',
    name: 'Jack Wilson',
    role: 'Gerente de Marketing',
    company: 'Magazine Luiza',
  },
]

const Homepage: FC = () => {
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopMin}px)`,
  )
  const [activeIndex, setActiveIndex] = useState(0)
  // const [carouselAnimation, setCarouselAnimation] = useState(false)
  const [permanent, setPermanent] = useState(false)
  const [conceptAnimation, setConceptAnimation] = useState(false)
  const { t } = useTranslation()
  // const customerTestimonial = CUSTOMER_TESTIMONIAL[activeIndex]
  const conceptRef = useRef(null)

  useEffect(() => {
    const objectRef = conceptRef.current

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setConceptAnimation(true)
          return
        }
        setConceptAnimation(false)
      })
    })
    if (objectRef) observer.observe(objectRef)

    return () => {
      if (objectRef) observer.unobserve(objectRef)
    }
  })

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (activeIndex < CUSTOMER_TESTIMONIAL.length - 1) {
  //       setActiveIndex(activeIndex + 1)
  //       setCarouselAnimation((prev: boolean) => !prev)
  //     } else {
  //       setActiveIndex(0)
  //       setCarouselAnimation((prev: boolean) => !prev)
  //     }
  //   }, 5000)

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   }
  // })

  useEffect(() => {
    const timer = setTimeout(() => {
      setPermanent(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  const renderImageAnimation = () => {
    const imageAnimation = IMAGE_ANIMATION?.map((items, key) => {
      return (
        <ImageAnimation
          key={key}
          src={items.image}
          top={items.top}
          right={items.right}
          animationUp={items.animationUp}
          animationType={items.animationType}
          permanent={permanent}
        />
      )
    })
    return imageAnimation
  }

  const renderIntroCards = () => {
    const introCards = INTRO_CARDS?.map((items, key) => {
      return (
        <IntroCard key={key}>
          <IconContainer>
            <Icon src={Lightning} />
          </IconContainer>
          <IntroCardTitlesContainer>
            <IntroCardTitle>{t(items.title)}</IntroCardTitle>
            <IntroCardText>{t(items.text)}</IntroCardText>
            <DotLine src={DotLineIcon} />
          </IntroCardTitlesContainer>
        </IntroCard>
      )
    })
    return introCards
  }

  const renderConceptions = CONCEPTION?.map((items, key) => {
    return isDesktop ? (
      <ConceptionsDiv key={key}>
        <ConceptionsIcon src={items.icon.desk} />
        <ConceptionsTitle>{t(items.title.desk)}</ConceptionsTitle>
      </ConceptionsDiv>
    ) : (
      <ConceptionsDiv key={key}>
        <ConceptionsIcon src={items.icon.mobile} />
        <ConceptionsTitle>{t(items.title.mobile)}</ConceptionsTitle>
      </ConceptionsDiv>
    )
  })

  const renderConceptionAnimation = CONCEPTION_ANIMATION?.map((items, key) => {
    return (
      <ConceptionAnimation
        ref={conceptRef}
        key={key}
        src={items.image}
        top={items.top}
        right={items.right}
        animation={items.animated}
      />
    )
  })

  const renderProjectCards = PROJECT_CARDS?.map((items, key) => {
    return (
      <ProjectCard key={key}>
        <ProjectCardIconContainer>
          <Icon src={items.icon} />
        </ProjectCardIconContainer>
        <ProjectCardTitle>{t(items.title)}</ProjectCardTitle>
        <ProjectCardText>{t(items.text)}</ProjectCardText>
      </ProjectCard>
    )
  })

  // const renderCarousel = () => {
  //   const carouselDots = CUSTOMER_TESTIMONIAL?.map((_items, key) => {
  //     const handleClick = () => {
  //       setActiveIndex(key)
  //       setCarouselAnimation((prev: boolean) => !prev)
  //     }
  //     return (
  //       <CarouselDot
  //         key={key}
  //         active={key === activeIndex}
  //         onClick={handleClick}
  //       />
  //     )
  //   })
  //   return (
  //     <CustomerTestimonialContainer>
  //       <CustomerQuoteContainer active={carouselAnimation}>
  //         <Icon src={Quote} />
  //         <CustomerQuote>{t(customerTestimonial.quote)}</CustomerQuote>
  //         <CustomerInfoContainer>
  //           <CustomerName>{t(customerTestimonial.name)}</CustomerName>
  //           <RoleCompanyContainer>
  //             <CustomerRole>{t(customerTestimonial.role)}</CustomerRole>
  //             <CustomerCompany>
  //               {t(customerTestimonial.company)}
  //             </CustomerCompany>
  //           </RoleCompanyContainer>
  //         </CustomerInfoContainer>
  //       </CustomerQuoteContainer>
  //       <TestimonialTitlesContainer>
  //         <TestimonialTitle>{t(HOME.testimonial.title)}</TestimonialTitle>
  //         <TestimonialSubTitle>
  //           {t(HOME.testimonial.subtitle)}
  //         </TestimonialSubTitle>
  //         <CarouselDotContainer>{carouselDots}</CarouselDotContainer>
  //       </TestimonialTitlesContainer>
  //     </CustomerTestimonialContainer>
  //   )
  // }

  return (
    <WrapProvider>
      <SeoElement title={SEO.home.title} description={SEO.home.description} />
      <SimpleContainer>
        <IntroSection>
          <PaddingContainer flex="column">
            <IntroSectionTop>
              <TitlesContainer>
                <Title>{t(HOME.title)}</Title>
                <Text>{t(HOME.subtitle)}</Text>
                <MainButtonLink to="/contact">
                  {t(GENERAL.buttons.contact)}
                </MainButtonLink>
              </TitlesContainer>
              <LogoAnimationContainer>
                {renderImageAnimation()}
              </LogoAnimationContainer>
            </IntroSectionTop>
            <IntroCardContainer>{renderIntroCards()}</IntroCardContainer>
          </PaddingContainer>
        </IntroSection>
        <ConceptionSection>
          {isDesktop ? (
            <PaddingContainer>
              <ConceptionTextContainer>
                <ConceptionSubTitle>
                  {t(HOME.conception.sectionName)}
                </ConceptionSubTitle>
                <ConceptionTitle>{t(HOME.conception.title)}</ConceptionTitle>
                <ConceptionText>{t(HOME.conception.subtitle)}</ConceptionText>
                <ConceptionsContainer>{renderConceptions}</ConceptionsContainer>
              </ConceptionTextContainer>
              <ConceptionAnimationContainer startAnimation={conceptAnimation}>
                {renderConceptionAnimation}
              </ConceptionAnimationContainer>
            </PaddingContainer>
          ) : (
            <PaddingContainer flex="column">
              <ConceptionTitle>{t(HOME.conception.title)}</ConceptionTitle>
              <ConceptionSubTitle>
                {t(HOME.conception.sectionName)}
              </ConceptionSubTitle>
              <ConceptionText>{t(HOME.conception.subtitle)}</ConceptionText>
              <ConceptionsContainer>{renderConceptions}</ConceptionsContainer>
            </PaddingContainer>
          )}
        </ConceptionSection>
        <ProjectSection>
          <PaddingContainer>
            <ProjectSectionTextContainer>
              <ProjectSectionTitle>
                {t(HOME.projects.title)}
              </ProjectSectionTitle>
              <ProjectSectionText>
                {t(HOME.projects.subtitle)}
              </ProjectSectionText>
              {/* <LightButtonLink to={'/projects'}>
                {t(GENERAL.buttons.more)}
              </LightButtonLink> */}
            </ProjectSectionTextContainer>
            <ProjectSectionCardsContainer>
              {renderProjectCards}
              <DotLineProject
                src={DotLineProjectImg}
                top="95"
                right="550"
                rotate={0}
              />
              <DotLineProject
                src={DotLineProjectImg}
                top="478"
                right="210"
                rotate={180}
              />
            </ProjectSectionCardsContainer>
          </PaddingContainer>
        </ProjectSection>
        {/* <CustomerTestimonial>
          <PaddingContainer>{renderCarousel()}</PaddingContainer>
        </CustomerTestimonial> */}
        <ContactUsSection>
          <PaddingContainer>
            <ContactUsContainer>
              <TitlesContainer>
                <Title>{t(HOME.contact.title)}</Title>
                <Text>{t(HOME.contact.subtitle)}</Text>
              </TitlesContainer>
              <FormContainer>
                <Form />
              </FormContainer>
            </ContactUsContainer>
          </PaddingContainer>
        </ContactUsSection>
      </SimpleContainer>
    </WrapProvider>
  )
}

export default Homepage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
